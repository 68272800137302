import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AutenticacaoService } from "../providers/autenticacao.service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable()
export class AutenticacaoGuard implements CanActivate {

  constructor(
    private authService: AutenticacaoService
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.controlarAcesso();
  }

  private controlarAcesso(): boolean {
    if (this.authService.autenticado()) {      
      return true;
    } else {
      this.authService.encaminheParaTelaLogin();
      return false;
    }
  }
}