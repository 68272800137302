<div class="listar-funcionalidade" [ngClass]="classe">
    <smk-funcionalidade [rotaVoltar]="rotaVoltar" [titulo]="titulo" [tituloComplementar]="tituloComplementar">
      <ng-content select="[acoes-barra]" ngProjectAs="[acoes-barra]"></ng-content>
      <ng-content select="[titulo-customizado]" ngProjectAs="[titulo-customizado]"></ng-content>
      <smk-filtro-funcionalidade
        class="mb-4"
        [configuracaoFiltrosUsuario]="configuracaoFiltrosUsuario"
        [filtroInformado]="filtroInformado"
        (filtrosChange)="filtrosChange.emit()"
        (filtrosClear)="filtrosClear.emit()">
        <ng-content select="[filtros]"></ng-content>
      </smk-filtro-funcionalidade>
      <smk-table-funcionalidade
        [configuracaoDaTable]="configuracaoDaTable"
        [itensDaTable]="itensDaTable"
        [exibirFerramentas]="exibirFerramentas"
        [colunasDisponiveis]="colunasDisponiveis"
        [configuracaoColunasUsuario]="configuracaoColunasUsuario"
        [ferramentas]="ferramentas"
        [idTenant]="idTenant"
        (colunasChange)="colunasChange.emit($any($event))"
        (colunasReset)="colunasReset.emit()"
        (selectedChange)="selectedChange.emit($any($event))"
        (pageChange)="pageChange.emit($any($event))">
        <ng-content select="[acoes-table]"></ng-content>
      </smk-table-funcionalidade>
    </smk-funcionalidade>
  </div>
