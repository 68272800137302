export enum PerfilUsuario {
  Administrador = 1,
  Comprador = 2,
  Fornecedor = 3,
  Requisitante = 4,
  Aprovador = 5,
  Gestor = 6,
  Cadastrador = 7,
  GestorDeFornecedores = 8,
  Recebimento = 9
}

