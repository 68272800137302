import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerModule } from './modules/container/container.module';
import { ContainerComponent } from './modules/container/container/container.component';
import { AutenticacaoGuard } from './shared/guards/autenticacao.guard';
import { LoginComponent } from './modules/login/login/login.component';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    loadChildren: () => ContainerModule,
    canActivate: [AutenticacaoGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    loadChildren: () => import('./modules/login/login.module').then(x => x.LoginModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
