import { Component, Input } from '@angular/core';

@Component({
  selector: 'smk-header-default',
  templateUrl: './smk-header-default.component.html',
  styleUrls: ['./smk-header-default.component.scss'],
})
export class SmkHeaderDefaultComponent  {
  @Input() topValue = '';
  @Input() heightValue = '64px';
  @Input() colorBackground = '#ffffff';
  @Input() showShadow = false;
  @Input() heightAuto = false;
  @Input() padding = '';
}
