import { AutenticacaoService } from 'src/app/shared/providers/autenticacao.service';
import { Menu } from './../../../shared/models/menu';
import { MenuService } from './../../../shared/providers/menu.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfilUsuario } from 'src/app/shared/models/enums/perfil-usuario';
import { LocalStorageService } from 'src/app/shared/providers/local-storage.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent {

  menu: Menu;
  nomeUsuario: string;
  perfilUsuario: string;

  constructor(
    private menuService: MenuService,
    private autenticacaoService: AutenticacaoService,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
  ) {
    this.menu = this.obterMenu();
  }

  ngOnInit(): void {
    this.nomeUsuario = this.autenticacaoService.usuario().pessoaFisica.nome;
    this.perfilUsuario = PerfilUsuario[this.autenticacaoService.perfil()];
  }

  navigateHome() {
    const a = 1;
    console.log(a);
  }

  navigateTo(rota: string) {
    this.router.navigate([rota], { relativeTo: this.route });
  }

  sair() {
    this.localStorageService.remove('accessToken');
    this.autenticacaoService.encaminheParaTelaLogin();
  }

  private obterMenu() {
    return this.menuService.obterMenu();
  }

}
