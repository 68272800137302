import { Injectable } from '@angular/core';
import { ItemMenu } from '../models/item-menu';
import { Menu } from '../models/menu';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  obterMenu(): Menu {

    return {
      menuPrincipal: this.obterItensMenuPrincipal(),
      menuLateral: []
    }
  }

  private obterItensMenuPrincipal(): ItemMenu[] {
    const itens = [
      {
        nome: 'Painel de operações',
        icone: 'fas fa-briefcase',
        rota: '/painel'
      },
      {
        nome: 'Dashboard',
        icone: 'fas fa-chart-pie',
        rota: '',
      },
    ]

    return itens;
  }

}
