import { Component, Input, TemplateRef } from '@angular/core';
import { ColumnTypeEnum } from '../../models/column-type.enum';
import { TableColumn } from '../../table/models/table-column';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'smk-column',
  templateUrl: './column.component.html',
  // tslint:disable-next-line: trailing-comma
  styleUrls: ['./column.component.scss']
})
export class ColumnComponent  {
  @Input() template: TemplateRef<any> | null = null;
  @Input() item!: any;
  @Input() column!: TableColumn;

  ColumnTypeEnum = ColumnTypeEnum;
}
