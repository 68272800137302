import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BackofficeComponent } from '../backoffice/backoffice/backoffice.component';
import { PainelComponent } from '../painel/painel/painel.component';
import { ContainerComponent } from './container/container.component';

const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
  },
  {
    path: 'painel',
    component: PainelComponent,
    loadChildren: () => import('./../painel/painel.module').then(x => x.PainelModule),
  },
  {
    path: 'painel/backoffice',
    loadChildren: () => import('./../backoffice/backoffice.module').then(x => x.BackofficeModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContainerRoutingModule { }
