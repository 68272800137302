import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  
  set(key: string | number, value: any) {
    window.localStorage[key] = value;
    return this;
  }

  get(key: string | number, defaultValue = null) {
    return window.localStorage[key] || defaultValue;
  }

  setObject(key: string | number, value: any) {
    window.localStorage[key] = JSON.stringify(value);
    return this;
  }

  getObject(key: string) {
    return JSON.parse(window.localStorage.getItem(key) || '');
  }

  remove(key: string) {
    window.localStorage.removeItem(key);
  }
}
