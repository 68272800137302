import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { BlockUIModule } from 'ng-block-ui';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AutenticacaoGuard } from './shared/guards/autenticacao.guard';
import { TextMaskModule } from 'angular2-text-mask';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    TextMaskModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    SharedModule.forRoot(),
    BlockUIModule.forRoot({
      message: 'Carregando...',
    }),
    BlockUIHttpModule.forRoot(),
    ToastrModule.forRoot(),
  ],
  providers: [AutenticacaoGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
