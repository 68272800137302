import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'smk-rectangle-button',
  templateUrl: './smk-rectangle-button.component.html',
  styleUrls: ['./smk-rectangle-button.component.scss']
})
export class SmkRectangleButtonComponent {
  constructor() {}

  @Input() leftIconClass: string = '';
  @Input() leftIconColor: string = '';

  @Input() label: string = '';
  @Input() type:
    | 'none'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'light'
    | 'dark' = 'none';
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
}
