<smk-barra-acoes-funcionalidade [rotaVoltar]="rotaVoltar">
  <ng-content select="[acoes-barra]"></ng-content>
</smk-barra-acoes-funcionalidade>
<div class="p-4">
  <smk-titulo-funcionalidade titulo="Painel de Operações">
    <ng-content select="[titulo-customizado]"></ng-content>
  </smk-titulo-funcionalidade>
  <div class="py-4">
    <div class="row">
        <div
          *ngFor="let configuracao of configuracoes; last as ultimaConfiguracao"
          class="col-md-6 col-lg-4 col-xxxl-3">
          <smk-card-funcionalidade>
            <div
              class="card-modulo d-flex flex-column justify-content-between align-items-center align-items-sm-start">
              <header class="header mb-4">
                <div class="d-flex align-items-center">
                  <div class="logo d-flex align-items-center justify-content-center">
                    <i class="icone" [ngClass]="configuracao.icone"></i>
                  </div>
                  <h5 class="title m-0 ms-3">{{ configuracao.titulo }}</h5>
                </div>
              </header>
              <section>
                <span class="texto">{{ configuracao.texto }}</span>
              </section>
              <hr>
              <footer class="footer">
                <span class="link-configuracoes" (click)="visualizarModulo(configuracao.rota)">Visualizar Módulo</span>
              </footer>
            </div>
          </smk-card-funcionalidade>
        </div>
      </div>
  </div>
</div>
