/* eslint-disable @typescript-eslint/no-empty-function */
import {  Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export abstract class Unsubscriber implements OnInit, OnDestroy {

  protected unsubscribe: Subject<void> = new Subject<void>();

 // eslint-disable-next-line @angular-eslint/contextual-lifecycle, @angular-eslint/no-empty-lifecycle-method
 ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
