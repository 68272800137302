import { Component, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './shared/providers/local-storage.service';

@Component({
  selector: 'backoffice-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Backoffice';

  constructor(
    private localStorageService: LocalStorageService,
  ) { }

  @HostListener('window:message', ['$event'])
  onMessage(e: any) {
    if (environment.marketplaceUrl.includes(e.origin)) {
      this.localStorageService.set('accessToken', e.data);
    }
  }
}
