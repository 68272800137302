import { TableComponent } from './../data-list/table/table.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { SmkCardFuncionalidadeComponent } from './smk-card-funcionalidade/smk-card-funcionalidade.component';
import { SmkTituloFuncionalidadeComponent } from './smk-titulo-funcionalidade/smk-titulo-funcionalidade.component';
import { SmkListarFuncionalidadeComponent } from './smk-listar-funcionalidade/smk-listar-funcionalidade.component';
import { SmkTableFuncionalidadeComponent } from './smk-table-funcionalidade/smk-table-funcionalidade.component';
import { SmkBarraAcoesFuncionalidadeComponent } from './smk-barra-acoes-funcionalidade/smk-barra-acoes-funcionalidade.component';
import { SmkFiltroFuncionalidadeComponent } from './smk-filtro-funcionalidade/smk-filtro-funcionalidade.component';
import { SmkFuncionalidadeComponent } from './smk-funcionalidade/smk-funcionalidade.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SmkHeaderDefaultComponent } from '../smk-header-default/smk-header-default.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColumnComponent } from '../data-list/components/column/column.component';
import { ColumnPipe } from '../data-list/pipes/column.pipe';
import { PagerComponent } from '../pager/pager.component';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { NgSelectModule } from '@ng-select/ng-select';
registerLocaleData(ptBr)

@NgModule({
  declarations: [
    ColumnComponent,
    ColumnPipe,
    TableComponent,
    PagerComponent,
    SmkTituloFuncionalidadeComponent,
    SmkCardFuncionalidadeComponent,
    SmkListarFuncionalidadeComponent,
    SmkTableFuncionalidadeComponent,
    SmkBarraAcoesFuncionalidadeComponent,
    SmkFiltroFuncionalidadeComponent,
    SmkFuncionalidadeComponent,
    SmkHeaderDefaultComponent,
  ],
  exports: [
    TableComponent,
    SmkTituloFuncionalidadeComponent,
    SmkCardFuncionalidadeComponent,
    SmkListarFuncionalidadeComponent,
    SmkTableFuncionalidadeComponent,
    SmkBarraAcoesFuncionalidadeComponent,
    SmkFiltroFuncionalidadeComponent,
    SmkFuncionalidadeComponent,
    SmkHeaderDefaultComponent,
    NgSelectModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
  ],
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule, NgSelectModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FuncionalidadeModule {}
