import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Unsubscriber } from './../../../shared/components/base/unsubscriber';

@Component({
  selector: 'app-painel',
  templateUrl: './painel.component.html',
  styleUrls: ['./painel.component.scss']
})
export class PainelComponent extends Unsubscriber implements OnInit{

  rotaVoltar: string;

  configuracoes = new Array<{ icone: string, titulo: string, texto: string, rota: string }>();

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
  }

  override ngOnInit() {
    this.inicialize();
  }
  private inicialize(): void {
    this.inicializeConfiguracoes();
  }

  private inicializeConfiguracoes(): void {
    this.configuracoes.push(
      {
        icone: 'fas fa-users-cog',
        titulo: 'FUP',
        rota: '/painel/backoffice',
        texto: `Tenha uma gestão unificada dos pedidos dos clientes. O FUP é a ferramenta para realizar o
          follow-up de todos os pedidos, comunicação com os fornecedores, dashboard com visão por clientes
          e fornecedores.`,
      });
  }

  visualizarModulo(rota: string) {
    this.router.navigate([rota], { relativeTo: this.route });
  }
}
