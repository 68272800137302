import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Unsubscriber } from 'src/app/shared/components/base/unsubscriber';
import { AutenticacaoService } from 'src/app/shared/providers/autenticacao.service';
import { LocalStorageService } from 'src/app/shared/providers/local-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends Unsubscriber implements OnInit, OnDestroy {

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private autenticacaoService: AutenticacaoService,
  ) {
    super();
  }

  override ngOnInit() {
    this.verifiqueToken();
  }

  private verifiqueToken() {
    this.blockUI.start();
    let count = 0;
    const interval = setInterval(() => {
      if (count >= 40) {
        this.blockUI.stop();
        clearInterval(interval);
        this.autenticacaoService.encaminheParaTelaLogin()
      }

      var token = this.localStorageService.get('accessToken');

      if (token) {
        const helper = new JwtHelperService();

        if (!helper.isTokenExpired(token)) {
          this.blockUI.stop();
          clearInterval(interval);
          this.router.navigate(['./../painel'], { relativeTo: this.route })
        }
      }

      count++;
    }, 500)
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
