import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DecodedToken } from '../models/decoded-token';
import { PerfilUsuario } from '../models/enums/perfil-usuario';
import { Usuario } from '../models/usuario';
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AutenticacaoService {

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private localStorage: LocalStorageService,
  ) { }

  perfil(): PerfilUsuario {
    return this.usuario().permissaoAtual.perfil;
  }

  usuario(): Usuario {
    const token = this.localStorage.get('accessToken');
    return JSON.parse(this.decodifiqueToken(token).usuario);
  }

  autenticado() {
    const helper = new JwtHelperService();
    const token = this.localStorage.get('accessToken');
    return token && !helper.isTokenExpired(token);
  }

  obtenhaTokenDecodificado(token: string): DecodedToken {
    return new DecodedToken(this.decodifiqueToken(token));
  }

  obtenhaTokenDaSessaoDecodificado(): DecodedToken {
    return new DecodedToken(this.decodifiqueToken(this.localStorage.get('accessToken')));
  }

  atualizeTokenDeAcesso(token: string): void {
    this.localStorage.set('accessToken', token);
  }

  encaminheParaTelaLogin() {
    window.location.href = environment.loginUrl;
  }

  private decodifiqueToken(token: string): any {
    return new JwtHelperService().decodeToken(token);
  }
}
