import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Unsubscriber } from '../base/unsubscriber';

@Component({
  selector: 'smk-modal',
  templateUrl: './smk-modal.component.html',
  styleUrls: ['./smk-modal.component.scss'],
})
export class SmkModalComponent extends Unsubscriber implements OnInit, OnDestroy {

  @Input() titulo = '';
  @Input() botaoSalvarHabilitado = false;
  @Input() salvarLabel = 'Salvar';
  @Input() salvarBtnClass = 'btn-success';
  @Input() fecharLabel = 'Fechar';
  @Input() fecharBtnClass = 'btn-outline-primary';

  @Output("fechar") fecharEmitter = new EventEmitter();
  @Output("salvar") salvarEmitter = new EventEmitter();
  
  constructor(
  ) {
    super();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  salvar() {
    this.salvarEmitter.emit();
  }

  fechar() {
    this.fecharEmitter.emit();
  }

}
