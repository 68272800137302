import { Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { Unsubscriber } from '../../../base/unsubscriber';

@Injectable()
export abstract class SmkComponent extends Unsubscriber implements OnInit, OnDestroy {

  @Input() classe: string;

  constructor() {
    super();
  }

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
