<div class="table-funcionalidade" [ngClass]="classe">
    <smk-card-funcionalidade paddingBottom="0.25rem">
      <div class="opcoes d-flex flex-column-reverse flex-md-row justify-content-between align-items-center pb-4">
        <div class="acoes text-center text-md-left">
          <ng-content></ng-content>
        </div>
        <div *ngIf="exibirFerramentas" class="ferramentas text-center text-md-right mb-4 mb-md-0">
          <button *ngFor="let ferramenta of ferramentas" class="exportar btn" [ngClass]="ferramenta.classe" [ngbTooltip]="ferramenta.tooltip"
            (click)="ferramenta.acao()">
            <i class="fas me-1" [ngClass]="ferramenta.icone"></i> {{ferramenta.label}}
          </button>
          <button
            class="configurar-colunas btn btn-primary"
            ngbTooltip="Configurar colunas"
            aria-label="Configurar colunas"
            (click)="soliciteConfiguracaoDeColunas(modalDeConfiguracaoDeColunasTmp)">
            <i class="fas fa-wrench"></i>
          </button>
        </div>
      </div>
      <smk-table
        [config]="configuracaoDaTable ? configuracaoDaTable : configuracaoDefaultDaTable"
        [items]="itensDaTable"
        (selectedChange)="selectedChange.emit($any($event))"
        (pageChange)="pageChange.emit($any($event))">
      </smk-table>
    </smk-card-funcionalidade>
  </div>

  <ng-template #modalDeConfiguracaoDeColunasTmp let-modal>
    <div class="modal-config-colunas">
      <div class="modal-header">
        <div class="modal-title">Configurar colunas</div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div id="modalBody" class="modal-body overflow-y-scroll">
        <p class="mt-2 mb-3">Adicione ou remova colunas.</p>
        <div [formGroup]="form">
          <ng-container formArrayName="configuracoesColuna">
            <div
              *ngFor="let _ of formConfiguracoesColuna.controls; index as i; last as isLast"
              [ngClass]="{ 'mb-2': isLast, 'mb-3': !isLast }">
              <ng-container [formGroupName]="i">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="w-100 me-3">
                    <ng-select
                      loadingText="Buscando..."
                      clearAllText="Limpar"
                      placeholder="Selecione"
                      [formControlName]="campoColuna"
                      [clearable]="true"
                      [searchable]="true"
                      [loading]="loadingColunas"
                      [class.invalid]="formConfiguracoesColuna.controls[i].get(campoColuna)!.invalid"
                      ngDefaultControl>
                      <ng-option *ngFor="let colunaDisponivel of colunasDisponiveis" [value]="colunaDisponivel.coluna">{{colunaDisponivel.label}}</ng-option>
                    </ng-select>
                  </div>
                  <div>
                    <i
                      *ngIf="formConfiguracoesColuna.controls.length > 1"
                      type="button"
                      class="remover-coluna far fa-trash-alt"
                      (click)="soliciteExclusaoDaColunaModal(i)"
                      data-toggle="tooltip"
                      data-placement="top"
                      container="body"
                      ngbTooltip="Remover coluna">
                    </i>
                    <i
                      *ngIf="formConfiguracoesColuna.controls.length === 1"
                      class="info-uma-coluna fas fa-info-circle"
                      data-toggle="tooltip"
                      data-placement="top"
                      container="body"
                      ngbTooltip="Pelo menos uma coluna deve estar configurada">
                    </i>
                  </div>
                </div>
                <div
                  *ngIf="formConfiguracoesColuna.controls[i].get(campoColuna)!.invalid"
                  class="container-de-erros">
                  <span class="invalid" *ngIf="formConfiguracoesColuna.controls[i].get(campoColuna)!.errors!['required']">
                    Selecione uma coluna
                  </span>
                  <span class="invalid"
                    *ngIf="formConfiguracoesColuna.controls[i].get(campoColuna)!.errors!['duplicated']">
                    Coluna já selecionada
                  </span>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer pt-3">
        <button
          class="adicionar-coluna btn btn-outline-success me-2"
          aria-label="Adicionar coluna"
          ngbTooltip="Adicionar coluna"
          (click)="adicioneColunaModal()">
          <i class="fas fa-plus"></i>
        </button>
        <button
          class="resetar-colunas btn btn-outline-primary me-2"
          aria-label="Voltar para a configuração original"
          ngbTooltip="Voltar para a configuração original"
          (click)="soliciteResetarConfiguracaoDeColunasModal()">
          <i class="fas fa-undo-alt"></i>
        </button>
        <div class="separador me-3">&nbsp;&nbsp;</div>
        <button type="button" class="col cancelar btn btn-outline-primary w-100 me-3" (click)="modal.dismiss()">
          Cancelar
        </button>
        <button
          type="button"
          class="col salvar btn btn-primary w-100"
          [class.disabled]="formConfiguracoesColuna.invalid"
          (click)="salveConfiguracoesDasColunasModal()">
          Confirmar
        </button>
      </div>
    </div>
  </ng-template>
