export enum ColumnTypeEnum {
  Text = 1,
  TextLowerCase = 2,
  TextUpperCase = 3,
  Date = 4,
  Time = 5,
  DateTime = 6,
  Decimal = 7,
  Currency = 8,
  Percent = 9,
  CustomTemplate = 10,
}
