<nav>
  <div class="row h-100">
    <div class="d-flex">
    <div class="col-2 h-100">
      <div class="d-flex ms-4 h-100">
            <img
            class="rounded-circle shadow-4-st bg-white  align-self-center"
            src="./../../../assets/img/logo-s.png"
            alt="Smarkets"
            width="40"
            height="40"
            />
          <span class=" ms-2 me-4 text-white  align-self-center">Operações Smarkets</span>
      </div>
    </div>
    <div class="col-6 d-lg-flex justify-content-start h-100 nav-item">
      <button
        *ngFor="let itemMenu of menu?.menuPrincipal; let i = index"
        (click)="navigateTo(itemMenu.rota);"
        class="btn h-100" >
        <i class="{{ itemMenu.icone }}  me-2 text-white"></i> {{ itemMenu.nome }}
      </button>
    </div>
    <div class="col-4 pe-3">
      <div class="row h-100">
        <div class="d-flex justify-content-end">
          <div class="col-10 col-xl-8 info-usuario-area">
            <div class="row h-100">
              <div class="d-flex justify-content-end">
                <div class="col-3 d-flex align-items-center justify-content-center">
                  <div class="circulo-info-usuario">
                    <i class="fas fa-user info-usuario-icon"></i>
                  </div>
                </div>
                <div class="col-5 info-usuario-text">
                  <p [title]="nomeUsuario" class="mb-0 text-truncate">{{nomeUsuario}}</p>
                  <p [title]="perfilUsuario" class="mb-0 text-truncate">{{perfilUsuario}}</p>
                </div>
                <div class="col-4 d-flex align-items-center justify-content-start">
                  <button class="info-usuario-sign-out" (click)="sair()">
                    <i class="px-2 fas fa-sign-out-alt"></i>
                    Sair
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</nav>
<div id="wrapper">
<!-- #region Page Content -->
<div id="page-content-wrapper">
  <router-outlet></router-outlet>
</div>
<!-- #endregion page-content-wrapper -->
</div>
