import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Mensagens } from './mensagens';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  constructor(private toastr: ToastrService) { }

  treatError(error: HttpErrorResponse): void {
    if (error && error instanceof HttpErrorResponse) {
      if (error.status === 400) {
        if (error.error) {
          if (error.error instanceof Array) {
            const message = new Array<string>();
            message.push('<ul>');
            message.push(...error.error.map((x) => `<li>${x.message}</li>`));
            message.push('</ul>');

            this.toastr.error(message.join(''), 'INCONSISTÊNCIAS:', { enableHtml: true });
          } else {
            this.toastr.error(error.error);
          }
        } else {
          this.toastrGenericError();
        }
      } else {
        this.toastrGenericError();
      }
    } else {
      this.toastrGenericError();
    }
  }

  parseErrorBlob(errorResponse: HttpErrorResponse): Observable<HttpErrorResponse> {
    const reader: FileReader = new FileReader();
    const isBlobError: boolean = errorResponse.error instanceof Blob && errorResponse.error.type === 'application/json';

    const httpErrorResponseObservable = new Observable<HttpErrorResponse>((observer: any) => {
      if (isBlobError) {
        reader.onloadend = () => {
          const error = JSON.parse(reader.result as string);
          const jsonError: any = { ...errorResponse, error };
          if (jsonError){
            observer.error(new HttpErrorResponse(jsonError));
            observer.complete();
          }
        };
      } else {
        observer.error(errorResponse);
        observer.complete();
      }
    });

    if (isBlobError) {
      reader.readAsText(errorResponse.error);
    }

    return httpErrorResponseObservable;
  }

  private toastrGenericError(): void {
    this.toastr.error(Mensagens.ERRO_INESPERADO);
  }
}
