<div class="funcionalidade" [ngClass]="classe">
  <smk-barra-acoes-funcionalidade [rotaVoltar]="rotaVoltar">
    <ng-content select="[acoes-barra]"></ng-content>
  </smk-barra-acoes-funcionalidade>
  <div class="p-4">
    <smk-titulo-funcionalidade [titulo]="titulo" [tituloComplementar]="tituloComplementar">
      <ng-content select="[titulo-customizado]"></ng-content>
    </smk-titulo-funcionalidade>
    <div class="py-4">
      <ng-content></ng-content>
    </div>
  </div>
</div>
